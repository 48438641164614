.table-logistics-details tr td {
  font-size: 9px !important;
  border: none;
}

.table-logistics-details {
  border-bottom: 1px solid black;
}

.additional-styles-for span i {
  font-size: 20px !important;
}
.additional-styles-for span p {
  font-size: 8px !important;
}
.tracking-no-header-1 {
  color: black;
  font-weight: bold;
  justify-content: center;
  padding: 10px;
  font-size: 30px;
  margin-bottom: 40px;
}
