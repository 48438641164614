.offer-title {
  color: #777;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 15px;
  padding-bottom: 5px;
  font-size: 20px;
  border-bottom: 1px solid gainsboro;
}

.offer-pay-now {
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}
.offer-pay-now span {
  color: white;
  background: #0db013;
  padding: 3px 10px;
  border-radius: 10px;
  margin-left: 10px;
}

.offer-range-text {
  text-align: center;
}

.logo-image-offer {
  width: 200px;
  height: auto;
}

@media (max-width: 600px) {
  .offer-title {
    font-size: 16px;
  }

  .offer-pay-now {
    font-size: 11px;
  }

  .offer-range-text {
    font-size: 11px;
  }

  .offer-box-text {
    font-size: 10px;
    padding: 7px 10px !important;
  }
  .logo-image-offer {
    width: 120px;
    height: auto;
  }
}
