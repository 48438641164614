@media (max-width: 991px){
.dashboard-left {
    position: fixed;
    height: 50vh;
    top: 0;
    /* left: -350px; */
    left: -10px;
    background-color: rgba(255, 255, 255, 1);
    z-index: 99;
    padding: 0 35px 35px;
    max-width: 350px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
}