.each-option {
  font-size: 12px;
  cursor: pointer;
}

.mobile-view {
  display: none;
}
.each-option:hover {
  color: #11677e;
}
.only-option-container {
  padding: 5px;
  padding-left: 30px;
  margin-top: 10px;
}
/* .option-container {
  } */

.left-list-column {
  padding: 10px 40px;
}
.left-list-container {
  background-color: white;
  padding: 25px;
  border-radius: 5px;
}
.vertical-line {
  width: 100%;
  height: 1px;
  background-color: gainsboro;
  margin-bottom: 15px;
}

.right-detail-column {
  padding: 10px 40px;
}

.top-row {
  padding: 12px;
  padding-bottom: 0px;
  background-color: white;
  border-radius: 5px;
}

.select-option {
  font-size: 16px;
  text-align: center;
  color: gray;
}

.main-order-section {
  background-color: white;
  border-radius: 5px;
}

.title-header {
  padding: 8px;
  margin-top: 7px;
  background-color: rgb(248, 248, 248);
}

.title-header div {
  font-size: 12px;
  color: gray;
}

.order-header {
  padding: 6px;
  background-color: white;
  width: 100%;
  justify-content: space-between;
  margin-left: 0px;
  padding-bottom: 15px;
  border-bottom: 2px solid gainsboro;
}

.each-order-container {
  background-color: white;
  justify-content: flex-start;
  padding: 10px;
  margin-top: 5px;
  padding-bottom: 0px;
}

.products-box {
  background-color: white;
  padding: 10px;
  padding-top: 0px;
  border-bottom: 1px solid gainsboro;
}
.info-box {
  color: #18768f !important;
  border: 1px solid #18768f !important;
  padding: 0px 3px;
  font-size: 10px;
}

.purchase-title {
  color: #18768f !important;
  font-size: 15px;
  padding: 5px 25px;
  font-weight: bold;
  border-bottom: 2px solid #18768f !important;
}

.payment-info-header {
  font-size: 12px;
  padding: 8px 5px;
  background-color: rgb(255, 255, 227);
  border: 1px solid #15819e !important;
}

.settlement-container {
  background-color: #e9e9e9;
  justify-content: space-between;
  z-index: 20000;
  width: 100%;
  margin: auto;
  margin-bottom: -10px;
  box-shadow: 0px 0px 10px #bdbdbd;
}
.sticky-settlement-container {
  position: sticky;
  bottom: 0;
}

@media (min-width: 1450px) {
  .main-container-cart {
    max-width: 1400px;
    margin: auto;
  }
  .parent-main-container-cart {
    background-color: rgb(248, 248, 248);
  }
}

.size-input-checkbox {
  margin-top: 0px;
}

.search-bar-row {
  padding: 20px;
  padding-right: 0px;
  padding-bottom: 10px;
  justify-content: flex-start;
}

.shop-title {
  font-size: 13px;
  font-weight: bold;
  margin-top: 2px;
  margin-left: 5px;
}

.product-image-title-row {
  padding-left: 40px;
}

.product-image-box {
  min-height: 100px;
  min-width: 100px;
  max-height: 100px;
  max-width: 100px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.product-image-box2 {
  min-height: 65px;
  min-width: 65px;
  max-height: 65px;
  max-width: 65px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.product-title-text {
  color: #4a4a4a;
  font-size: 13px;
  padding-bottom: 15px;
}

.main-container-cart {
  display: block;
}

@media (max-width: 600px) {
  .right-detail-column {
    padding: 5px !important;
  }
  .mobile-view {
    display: block;
  }

  .each-order-container {
    padding: 0px 5px;
  }
  .product-checkbox {
    margin-left: 0px !important;
  }
  .payment-info-header,
  .title-header {
    display: none;
  }

  .search-bar-row {
    padding: 0px;
  }

  .order-header {
    margin-top: -15px;
    background-color: #fff !important;
    border-bottom: 1px solid gainsboro !important;
    display: flex;
    flex-direction: row;
    padding: 10px !important;
  }

  .form-check {
    margin-left: 8px;
    margin-top: 6px;
  }

  .shop-title {
    font-size: 10px;
    padding: 10px 0px;
    margin-left: 0px !important;
  }
  .more-offers {
    font-size: 8px;
    padding-top: 13px;
    padding-right: 4px;
  }
  .more-offers i {
    display: none;
  }
  .product-image-title-row {
    padding-left: 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .product-image-box {
    max-height: 70px;
    max-width: 70px;
    min-width: 70px;
    min-height: 70px;
  }
  .product-image-box2 {
    max-height: 60px;
    max-width: 60px;
    min-width: 60px;
    min-height: 60px;
  }

  .product-title-text {
    font-size: 10px;
    margin-left: 10px;
  }

  .main-container-cart {
    display: none;
  }

  .products-box {
    display: flex;
    flex-direction: column;
    padding: 10px 5px;
  }

  .image-title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .size-color-variant-row {
    justify-content: space-between;
    flex-direction: row;
    padding: 15px 10px;
    background-color: rgb(247 247 247);
    display: flex;
    width: 100%;
  }

  .qty-plus-minus {
    padding: 4px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #18768f;
    border: 1px solid #18768f;

    max-height: 25px;
    font-size: 10px;
  }

  .form-check-input {
    accent-color: #18768f !important;
  }

  .qty-input-box {
    background-color: white;
    padding: 4px 5px;
    border-top: 1px solid #18768f;
    border-bottom: 1px solid #18768f;
    border-left: none;
    border-right: none;
    max-height: 25px;
    font-size: 10px;
    max-width: 50px;
    text-align: center;
  }

  .product-variant-row-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px 0px;
    padding-bottom: 0px;
  }

  .purchase-title {
    font-size: 13px !important;
  }

  .sticky-settlement-container {
    bottom: 60px !important;
  }

  .checkbox-round {
    width: 20px !important;
    height: 20px !important;
    background-color: white !important;
    border-radius: 50% !important;
    vertical-align: middle !important;
    border: 1px solid rgb(204, 204, 204) !important;
    appearance: none !important;
    -webkit-appearance: none !important;
    outline: none !important;
    cursor: pointer !important;
    margin-right: 7px;
  }

  .checkbox-round:checked {
    background-color: #18768f !important;
    border-color: #18768f !important;
  }
}

input[type="checkbox"] {
  accent-color: #18768f !important;
  mix-blend-mode: multiply;
}
.form-check-input:checked {
  background-color: #18768f !important;
  border-color: #18768f !important;
}

.products-box-refund {
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
}

.image-title-container-refund {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.product-image-title-row-refund {
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.product-checkbox-refund {
  margin-left: 0px !important;
}
.checkbox-round-refund {
  width: 20px !important;
  height: 20px !important;
  background-color: white !important;
  border-radius: 50% !important;
  vertical-align: middle !important;
  border: 1px solid rgb(204, 204, 204) !important;
  appearance: none !important;
  -webkit-appearance: none !important;
  outline: none !important;
  cursor: pointer !important;
  margin-right: 7px;
}

.checkbox-round-refund:checked {
  background-color: #18768f !important;
  border-color: #18768f !important;
}

.product-image-box-refund {
  max-height: 70px;
  max-width: 70px;
  min-width: 70px;
  min-height: 70px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.product-title-text-refund {
  font-size: 10px;
  margin-left: 10px;
}

.product-variant-row-container-refund {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px 0px;
  padding-bottom: 0px;
}

.product-image-box2-refund {
  max-height: 60px;
  max-width: 60px;
  min-width: 60px;
  min-height: 60px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.size-color-variant-row-refund {
  justify-content: space-between;
  flex-direction: row;
  padding: 15px 10px;
  background-color: rgb(247 247 247);
  display: flex;
  width: 100%;
}

.qty-plus-minus-refund {
  padding: 4px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #18768f;
  border: 1px solid #18768f;

  max-height: 25px;
  font-size: 10px;
}

.qty-input-box-refund {
  background-color: white;
  padding: 4px 5px;
  border-top: 1px solid #18768f;
  border-bottom: 1px solid #18768f;
  border-left: none;
  border-right: none;
  max-height: 25px;
  font-size: 10px;
  max-width: 50px;
  text-align: center;
}
