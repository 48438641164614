.make-payment-header {
  color: black;
  text-transform: none;
  font-size: 250%;
  text-align: center;
}

.make-payment-container {
  background-color: white;
  padding: 20px 0px;
}

.padding-right-disable {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.upload-title {
  font-size: 140%;
  color: white;
  font-weight: bold;
  background-color: transparent;
  border-bottom: 1px solid white;
}

.discount-added {
  color: white;
  background: #0db013;
  padding: 3px 10px;
  border-radius: 10px;
  margin-left: 10px;
  font-size: 11px;
}

@media (max-width: 600px) {
  .make-payment-header {
    font-size: 180%;
  }
  .upload-title {
    font-size: 100%;
  }

  .discount-added {
    font-size: 8px;
  }

  .nav-link.each-option {
    font-size: 70%;
    padding: 10px;
  }

  .make-payment-container {
    padding: 10px 0px !important;
  }

  .padding-right-disable {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .final-payment-modal {
    min-width: 100% !important;
  }
}
