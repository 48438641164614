.stripe-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.successpage-table {
  margin: 1rem;
}

.payment-header h5 {
  margin-left: 2rem;
  color: gray;
}

.payment-fullbox {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.payment-options {
  display: flex;
  flex-direction: row;
}

.choose-options {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.payment-option {
  padding: 1rem;
  background: #f9f9f9;
  text-align: center;
  cursor: pointer;
  color: darkgray;
}
.payment-option.not-selected {
  background: white !important;
}
.payment-box {
  border: none !important;
  background: #f9f9f9;
}
.payment-description {
  padding-top: 0% !important;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin: auto;
}

.payment-image {
  max-height: 30px;
}
.payment-logo {
  max-width: 5% !important;
  padding: 0.3rem;
}

.cityBank {
  max-width: 50% !important;
}
