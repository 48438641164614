@media (max-width: 800px) {
  .notice-header-text {
    font-size: 80%;
  }

  .see-more-button {
    padding: 5px 9px !important;
  }

  .notice-list {
    font-size: 80% !important;
  }
}
@media (max-width: 650px) {
  .notice-board-container {
    max-width: 90% !important;
    margin: auto !important;
    margin-bottom: 30px !important;
  }
}
@media (min-width: 651px) {
  .notice-board-container {
    max-width: 56% !important;
  }
  .both-notice-board-container {
    justify-content: space-around !important;
  }
}

.animatable-row {
  animation: animateNotice 276s linear infinite;
  margin-bottom: 40px;
  letter-spacing: 1px;
}

@keyframes animateNotice {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 3952px 0;
  }
}
