.product-detail-div{
    padding: 3rem !important;
    position: fixed;
    z-index: 5;
}

.order-details-flexbox{
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgb(223, 220, 220);
}
.order-details-flexbox div{
    max-width: 50%;

}

.popover-body-container{
    max-height: 50vh;
    overflow-y: scroll;
}

@media(max-width:1200px){
    .dashboard-right .dashboard {
     min-width: 110%;
    }
}
@media(max-width:580px){
    .dashboard-right .dashboard {
     min-width: 100%;
    }
}

@media(min-width:760px){
    .mobile-payment-button{
       display: none !important;
    }
}