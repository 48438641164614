.searchbar-container {
  display: flex;
  flex-direction: row;
  width: 60%;
  text-align: center !important;
  padding-bottom: 0rem;
  margin: auto;
}

.searchbar-container .form_search {
  width: 100%;
  text-align: center !important;
  border: 1px solid gainsboro;
  border-radius: 0px;
}
.accordion-padding {
  padding: 0px;
  margin-top: 10px;
}
.header-text-1 {
  margin-top: 40px;
  font-size: 35px;
  font-weight: bold;
  letter-spacing: 3px;
  justify-content: center;
}

.header-text-2 {
  letter-spacing: 1px;
  justify-content: center;
  font-size: 16px;
}

.ship-for-me-image-container {
  width: 60%;
  margin: 0px auto;
}

@media (max-width: 500px) {
  .header-text-1 {
    margin-top: 16px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    justify-content: center;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .list-container {
    padding-left: 5px;
  }

  .accordion-padding {
    padding: 10px;
    padding-bottom: 0px;
    margin-top: 0px;
  }

  .header-text-2 {
    padding: 10px;
    letter-spacing: 1px;
    justify-content: center;
    font-size: 14px;
    padding-top: 5px;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .taobao-support {
    margin-top: 0px !important;
  }

  .ship-for-me-image-container {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .searchbar-container .form_search {
    display: block !important;
    border: 1px solid gainsboro;
    border-radius: 0px;
  }
}

@media (max-width: 790px) {
  .searchbar-container {
    width: 100%;
  }
}
@media (min-width: 790px) {
  .searchbar-container .form_search {
    display: block !important;
    width: 100%;
    border: 1px solid gainsboro;
    border-radius: 0px;
  }
}
@media (min-width: 1190px) {
  .searchbar-container .form_search {
    display: block !important;
    width: 100%;
    border: 1px solid gainsboro;
    border-radius: 0px;
  }
}
@media (min-width: 1490px) {
  .searchbar-container .form_search {
    display: block !important;
    width: 100%;
    border: 1px solid gainsboro;
    border-radius: 0px;
  }
}
@media (min-width: 1060px) {
  .search-bar-logo {
    margin: auto;
    margin-top: 50px;
    /* background-image: url("./searchbar-background.jpg"); */
    background-size: contain;
    max-width: 60%;
    background-color: white;
    border-radius: 10px;
  }
  .header-h4 {
    font-size: 150% !important;
  }
}
@media (max-width: 1060px) {
  .search-bar-logo {
    margin: 20px;
    /* background-image: url("./searchbar-background.jpg"); */
    background-size: contain;
    background-color: white;
    border-radius: 10px;
  }
}

.taobao-support {
  text-transform: none !important;
}

.header-text-box {
  padding: 0 !important;
}

.image-input-container {
  display: flex;
  flex-direction: row;
}

.search-by-image {
  cursor: pointer;
  font-size: 18px !important;
  color: #ff4c3b;
  padding-left: 15px;
  padding-right: 15px;
  background: #ff4c3b;
  padding-top: 12px;
}
.upload-image {
  cursor: pointer;
  overflow: hidden !important;
  max-width: 50px;
  opacity: 0;
  position: absolute;
  min-height: 45px;
}

@media (min-width: 1000px) {
  .home-slider .home.home45,
  .center-slider .home.home45 {
    width: 900px;
    height: 300px;
    border-radius: 5px;
  }
  .home-slider .home.home46,
  .center-slider .home.home46 {
    width: 900px;
    height: 300px;
    border-radius: 5px;
  }
}
@media (min-width: 1000px) {
  .home-page-main-slider {
    width: 900px;
    height: 300px;
    border-radius: 10px;
  }

  .search-bar-logo {
    max-width: 1550px;
    margin: auto;
    margin-top: 50px;
  }
}
@media (min-width: 1000px) {
  .slider-inquiry-bar {
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* background-color: rgb(250, 250, 250); */
    padding: 30px;
    background-color: #f8f8f8;
  }
}

.next-button:hover {
  background-color: orange;
}

.select-outline-style:active,
.select-outline-style:hover {
  outline-color: orange;
  border-color: orange;
}
.table-bordered td {
  border: 1px solid #a5bed7;
}

.booking-rate-header {
  color: #666;
  font-weight: bold;
  padding: 10px;
  font-size: 30px;
  justify-content: center;
}

.booking-form-container {
  display: flex;
  flex-direction: row;
}

.booking-input-box {
  flex: 1;
  margin-right: 5px;
}
.input-container {
  background-color: white;
  border-radius: 5px;
  padding: 50px;
  padding-top: 10px;
}
.result-container {
  background-color: white !important;
  border-radius: 5px;
  padding: 50px;
  padding-top: 10px;
  margin-top: 20px;
  padding-bottom: 100px;
}

.label-title {
  color: #666;
  margin-bottom: 3px;
  font-size: 14px;
}
@media (max-width: 480px) {
  .booking-rate-header {
    font-size: 15px;

    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .booking-form-container {
    flex-direction: column;
    margin: 15px !important;
  }
  .input-container {
    padding: 0px;
    padding-bottom: 20px;
  }
  .input-container button {
    width: 100%;
  }
  .result-container {
    padding: 10px !important;
  }

  .main-container {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .product-link {
    margin-top: 30px !important;
  }
  .label-title {
    font-size: 10px;
  }

  .final-booknow {
    margin-right: 5px !important;
  }
}
