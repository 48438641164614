.top-searchbar-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.search-form-taobao {
  width: 70% !important;
  margin: auto;
}
.searchbar-container .form_search {
  width: 100%;
  text-align: center !important;
  border: 1px solid gainsboro;
  border-radius: 0px;
}

.header-text-1 {
  margin-top: 40px;
  font-size: 35px;
  font-weight: bold;
  letter-spacing: 3px;

  text-align: center;
}

.header-text-2 {
  letter-spacing: 1px;

  font-size: 16px;
  text-align: center;
}

@media (max-width: 500px) {
  .search-form-taobao {
    width: 100% !important;
    margin: auto;
  }
  .header-text-1 {
    margin-top: 16px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;

    text-align: center;
  }

  .header-text-2 {
    padding: 10px;
    letter-spacing: 1px;
    text-align: center;
    font-size: 14px;
    padding-top: 5px;
  }
  .taobao-support {
    margin-top: 0px !important;
  }
}
@media (max-width: 768px) {
  .searchbar-container .form_search {
    display: block !important;
    border: 1px solid gainsboro;
    border-radius: 0px;
  }
}

@media (max-width: 790px) {
  .searchbar-container {
    width: 100%;
  }
}
@media (min-width: 790px) {
  .searchbar-container .form_search {
    display: block !important;
    width: 100%;
    border: 1px solid gainsboro;
    border-radius: 0px;
  }
}
@media (min-width: 1190px) {
  .searchbar-container .form_search {
    display: block !important;
    width: 100%;
    border: 1px solid gainsboro;
    border-radius: 0px;
  }
}
@media (min-width: 1490px) {
  .searchbar-container .form_search {
    display: block !important;
    width: 100%;
    border: 1px solid gainsboro;
    border-radius: 0px;
  }
}
@media (min-width: 1060px) {
  .search-bar-logo {
    margin: auto;
    margin-top: 50px;
    /* background-image: url("./searchbar-background.jpg"); */
    background-size: contain;
    max-width: 60%;
    background-color: white;
    border-radius: 10px;
  }
  .header-h4 {
    font-size: 150% !important;
  }
}
@media (max-width: 1060px) {
  .search-bar-logo {
    margin: 20px;
    margin-left: 0px;
    margin-right: 0px;
    /* background-image: url("./searchbar-background.jpg"); */
    background-size: contain;
    background-color: white;
    border-radius: 10px;
  }
}

.taobao-support {
  text-transform: none !important;
}

.header-text-box {
  padding: 0 !important;
}

.image-input-container {
  display: flex;
  flex-direction: row;
}

.search-by-image {
  cursor: pointer;
  font-size: 18px !important;
  color: #ff4c3b;
  padding-left: 15px;
  padding-right: 15px;
  background: #ff4c3b;
  padding-top: 12px;
}
.upload-image {
  cursor: pointer;
  overflow: hidden !important;
  max-width: 50px;
  opacity: 0;
  position: absolute;
  min-height: 45px;
}

@media (min-width: 1000px) {
  .home-slider .home.home45,
  .center-slider .home.home45 {
    width: 900px;
    height: 300px;
    border-radius: 5px;
  }
  .home-slider .home.home46,
  .center-slider .home.home46 {
    width: 900px;
    height: 300px;
    border-radius: 5px;
  }
}
@media (min-width: 1000px) {
  .home-page-main-slider {
    width: 900px;
    height: 300px;
    border-radius: 10px;
  }

  .search-bar-logo {
    max-width: 1550px;
    margin: auto;
    margin-top: 50px;
  }
}
@media (min-width: 1000px) {
  .slider-inquiry-bar {
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* background-color: rgb(250, 250, 250); */
    padding: 30px;
    background-color: #f8f8f8;
  }
}

.next-button:hover {
  background-color: orange;
}

.select-outline-style:active,
.select-outline-style:hover {
  outline-color: orange;
  border-color: orange;
}
