@media (min-width: 1100px) {
  .product-container-section {
    max-width: 60%;
    min-width: 60%;
    margin-left: 5rem !important;
    background-color: rgb(255, 255, 255);
    margin-top: 2rem;
    margin-bottom: 3rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 7px;
  }

  .shipping-info-div {
    max-width: 90%;
    min-width: 90%;
  }
}
