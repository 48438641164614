.selected-option {
  color: black !important;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 2px solid #f54c3c;
}
.category-list-shipping {
  padding: 7px;
  font-weight: bold;
  padding-top: 0;
  font-size: 13px;
}
.extra-height {
  height: calc(2.25rem + 2px);
}

.each-progress {
  height: 3px;
  background: #18768f;
}
.each-progress-2 {
  height: 3px;
  background: gainsboro;
}
.each-progress i {
  color: white;
  font-size: 14px;
  position: absolute;
  background-color: #18768f;
  left: 0px;
  top: -14px;
  padding: 4px;
  border: 3px solid gainsboro;
  border-radius: 50%;
  font-weight: bold;
}
.each-progress span {
  text-align: center;
  position: relative;
  top: 13px;
  left: -20px;
  font-size: 9px;
  font-weight: bold;
}
.each-progress-2 i {
  color: gainsboro;
  font-size: 14px;
  position: absolute;
  background-color: white;
  left: 0px;
  top: -14px;
  padding: 4px;
  border: 3px solid gainsboro;
  border-radius: 50%;
  font-weight: bold;
}
.each-progress-2 span {
  text-align: center;
  position: relative;
  top: 13px;
  left: -20px;
  font-size: 9px;
  font-weight: bold;
  color: gray;
}
.last-progress i {
  color: white;
  font-size: 14px;
  position: absolute;
  background-color: #18768f;
  left: 0px;
  top: -14px;
  padding: 4px;
  border: 3px solid gainsboro;
  border-radius: 50%;
  font-weight: bold;
}
.last-progress span {
  text-align: center;
  position: relative;
  top: 13px;
  left: -20px;
  font-size: 9px;
  font-weight: bold;
}
.last-progress-2 i {
  color: gainsboro;
  font-size: 14px;
  position: absolute;
  background-color: white;
  left: 0px;
  top: -14px;
  padding: 4px;
  border: 3px solid gainsboro;
  border-radius: 50%;
  font-weight: bold;
}
.last-progress-2 span {
  text-align: center;
  position: relative;
  top: 13px;
  left: -20px;
  font-size: 9px;
  font-weight: bold;
  color: gray;
}
.each-progress-3 {
  height: 2px;
  background: #18768f;
  max-width: 14%;
}
.each-progress-4 {
  height: 2px;
  background: gainsboro;
  max-width: 14%;
}
.last-progress-3 {
  max-width: 14%;
  padding-right: 0px !important;
}
.last-progress-4 {
  max-width: 14%;
  padding-right: 0px !important;
}
.each-progress-3 i {
  color: white;
  font-size: 9px;
  position: absolute;
  background-color: #18768f;
  left: 0px;
  top: -10px;
  padding: 3px;
  border: 2px solid gainsboro;
  border-radius: 50%;
  font-weight: bold;
}
.each-progress-3 span {
  text-align: center;
  position: relative;
  top: 2px;
  left: -17px;
  font-size: 6px;
  font-weight: bold;
}
.each-progress-4 i {
  color: gainsboro;
  font-size: 9px;
  position: absolute;
  background-color: white;
  left: 0px;
  top: -10px;
  padding: 3px;
  border: 2px solid gainsboro;
  border-radius: 50%;
  font-weight: bold;
}
.each-progress-4 span {
  text-align: center;
  position: relative;
  top: 2px;
  left: -17px;
  font-size: 6px;
  font-weight: bold;
  color: gray;
}
.last-progress-3 i {
  color: white;
  font-size: 9px;
  position: absolute;
  background-color: #18768f;
  left: 0px;
  top: -10px;
  padding: 3px;
  border: 2px solid gainsboro;
  border-radius: 50%;
  font-weight: bold;
}
.last-progress-3 span {
  text-align: center;
  position: relative;
  top: 2px;
  left: -17px;
  font-size: 6px;
  font-weight: bold;
}
.last-progress-4 i {
  color: gainsboro;
  font-size: 9px;
  position: absolute;
  background-color: white;
  left: 0px;
  top: -10px;
  padding: 3px;
  border: 2px solid gainsboro;
  border-radius: 50%;
  font-weight: bold;
}
.last-progress-4 span {
  text-align: center;
  position: relative;
  top: 2px;
  left: -17px;
  font-size: 6px;
  font-weight: bold;
  color: gray;
}
.buy-ship-for-me-mobile {
  margin-left: 5px !important;
}
