.make-payment-header {
  color: black;
  text-transform: none;
  font-size: 250%;
  text-align: center;
}

.make-payment-container {
  background-color: white;
  padding: 20px 0px;
}

.padding-right-disable {
  padding-right: 0px !important;
  padding-left: 30px !important;
}

@media (max-width: 600px) {
  .make-payment-header {
    font-size: 180%;
  }

  .nav-link.each-option {
    font-size: 70%;
    padding: 10px;
  }

  .make-payment-container {
    padding: 10px 0px !important;
  }

  .padding-right-disable {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .final-payment-modal {
    min-width: 100% !important;
  }
}

.table th,
.table td {
  vertical-align: middle !important;
}

.nav-link.each-option {
  color: gray;
}
