.sign-out-button {
  color: white !important;
  cursor: pointer;
}

.onhover-dropdown .profile-info-container {
  min-width: 300px !important;
  box-shadow: gray 3px 4px 10px 1px !important;
  margin-top: 10px;
  border-radius: 5px !important;
}

@media (max-width: 700px) {
  .onhover-dropdown .profile-info-container {
    right: -70px !important;
    bottom: 52px !important;
  }
  .square-box {
    top: unset !important;
    bottom: -15px;
    right: 75px !important;
  }
}
@media (max-width: 370px) {
  .onhover-dropdown .profile-info-container {
    right: -85px !important;
  }
  .square-box {
    right: 90px !important;
  }
}
@media (max-width: 320px) {
  .onhover-dropdown .profile-info-container {
    right: -92px !important;
  }
  .square-box {
    right: 98px !important;
  }
}
.profile-info-container li {
  padding: 5px;
  font-size: 16px !important;
  margin-bottom: 10px;
  cursor: pointer;
}
.profile-info-container li a:hover {
  color: #f48110 !important;
}

.welcome-greatings {
  color: #383838;
  padding: 10px;
  border-bottom: 1px solid #18768f;
  font-size: 19px;
  font-weight: bold;
}

.square-box {
  height: 30px;
  width: 30px;
  top: -15px;
  right: 45px;
  position: absolute;
  background: white;
  transform: rotate(45deg);
  border-bottom: none;
  border-right: none;
}
