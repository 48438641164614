.table-logistics-details tr td {
  font-size: 9px !important;
  border: none;
}

.table-logistics-details {
  border-bottom: 1px solid gainsboro;
}

.additional-styles-for span i {
  font-size: 20px !important;
}
.additional-styles-for span p {
  font-size: 8px !important;
}
