.slick-track {
  margin: auto !important;
}
.small-image-container-div {
  max-width: 90%;
  min-width: 90%;
  margin: auto;
}

@media (max-width: 600px) {
  .small-image-container-div {
    max-width: 85%;
    min-width: 85%;
    /* overflow: hidden; */
  }
}

.slick-slide.slick-active {
  padding: 0px !important;
}
.slider-nav .slick-slide > div {
  margin: 0px !important;
  margin-top: 5px !important;
  border: none !important;
}
