@media (max-width: 600px) {
  .remove-padding-mobie-view {
    padding: 0px !important;
  }
}
@media (min-width: 600px) {
  .filter-container {
    max-width: 400px;
    margin-left: 10px;
    margin-bottom: 20px;
  }
}

.filter-container {
  margin-bottom: 20px;
}

._loading_overlay_overlay {
  background: rgb(184 184 184 / 70%) !important;
}

._loading_overlay_content {
  margin-top: 400px !important;
}
