.request_popup {
  max-width: 1370px;
  border-radius: 10px;
  overflow: hidden;
  margin: auto;
}
.request_popup .modal-content {
  border: none;
  border-radius: 10px;
  overflow: hidden;
}
.request_popup .close {
  position: absolute;
  opacity: 1;
  color: #fe8704;
  top: 15px;
  right: 15px;
  z-index: 99;
  text-shadow: none;
}
.center-head {
  color: #202120;
  padding: 0;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: lighter;
  margin: 10px 0 30px;
  text-align: center;
  position: relative;
}
.center-head:after {
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  border-bottom: 1px solid #ccc;
  top: 50%;
  /* margin-top: -1px; */
  z-index: 9;
}

.center-head span {
  display: inline-block;
  padding: 0 20px;
  position: relative;
  z-index: 99;
  font-weight: 600;
  background-color: rgb(245, 245, 245);
  color: #fb8c00;
}

.request_popup .close:hover {
  transform: rotate(360deg);
}
.request_popup .login-img-bg {
  background-image: url(./Account.png);
  background-size: cover;
  margin-top: 50px;
}

.login-bg-image {
  background-image: url(./loginBg.png);
  background-size: cover;
  z-index: 0 !important;
}

.request_pag_img {
  min-height: 400px;
}

.commnets-reply .btn-theme {
  font-size: 0.75rem;
  padding: 0.4rem 1.5rem;
}

.footer-widget-tweet .btn-theme {
  background: #232135;
  font-size: 13px;
  margin-top: 30px;
}
.footer-widget-tweet .btn-theme i {
  margin: 0;
  color: #fff;
}

.google-sign-in {
  padding: 9px;
  background-color: #f3f3f3;
  color: black;
  font-weight: bold;
  margin: auto;
  width: 70%;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 10px;
}

.mobile-sign-in {
  padding: 9px;
  background-color: #9f2fae;
  color: white;
  font-weight: bold;
  width: 70%;
  margin: auto;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 10px;
}
.mobile-sign-in i {
  font-size: 140%;
  font-weight: bolder;
  padding-right: 6px;
}

.facebook-sign-in {
  padding: 12px;
  background-color: black;
  color: white;
  font-weight: bold;
  width: 70%;
  margin: auto;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 10px;
}
.facebook-sign-in i {
  font-size: 140%;
  font-weight: bolder;
  padding-right: 6px;
}

@media (max-width: 1000px) {
  .request_pag_img {
    min-height: 200px;
  }
}
@media (min-width: 1000px) {
  .center-head {
    margin-top: 40px;
  }

  .sign-in-header-title {
    margin-top: 40px;
  }

  .input-field-70 {
    max-width: 70%;
  }
}
@media (min-width: 1000px) {
  .modal-content.login-modal {
    max-width: 100%;
    margin: auto;
  }
  .modal-content.phone-modal {
    max-width: 400px;
    margin: auto;
  }
  .modal-content.otp-modal {
    max-width: 400px;
    margin: auto;
  }
  .modal-content.signup-modal {
    max-width: 70%;
    margin-left: 12rem;
  }
  .modal-content.final-payment-modal {
    max-width: 50%;
  }
}

.login-board {
  margin: 120px;
  margin-right: 80px;
  margin-bottom: 239px;
  transform: translateX(-35%) translateY(10%) skewX(10deg);
  transition: transform 0.3s ease-in;
}
.login-board-final {
  transform: translateX(0) translateY(0) skewX(0);
}

.login-board-signup {
  margin: 120px;
  margin-top: 80px;
  margin-right: 40px;
  transform: translateX(-35%) translateY(10%) skewX(10deg);
  transition: transform 0.3s ease-in;
}

.signup-board-final {
  transform: translateX(0) translateY(0) skewX(0);
}

@media (max-width: 1000px) {
  .login-img-bg {
    display: none;
  }

  .login-board {
    margin: 0px;
  }
  .login-board-signup {
    margin: 0px;
  }

  .request_popup {
    margin-top: 50px;
    padding: 10px;
  }
}
