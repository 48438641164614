.make-payment-header {
  color: black;
  text-transform: none;
  font-size: 250%;
  text-align: center;
}

.make-payment-container {
  background-color: white;
  padding: 20px 0px;
}

.padding-right-disable {
  padding-right: 0px !important;
  padding-left: 30px !important;
}
.pay-with-bkash {
  height: 40px;
  max-height: 40px;
  width: 175px;
  max-width: 175px;
  cursor: "pointer";
}
@media (max-width: 600px) {
  .pay-with-bkash {
    height: 30px;
    max-height: 30px;
    width: 135px;
    max-width: 135px;
    cursor: "pointer";
  }
  .make-payment-header {
    font-size: 180%;
  }

  .nav-link.each-option {
    font-size: 70%;
    padding: 10px;
  }

  .make-payment-container {
    padding: 10px 0px !important;
  }

  .padding-right-disable {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .final-payment-modal {
    min-width: 100% !important;
  }
}
