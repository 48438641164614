.header-container-left {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.mobile-navbar div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  color: rgb(70, 70, 70);
  font-size: 20px;
  margin-top: 3px;
}
.mobile-navbar .icon-div {
  margin-top: 0px !important;
  padding: 5px 10px;
  box-shadow: 12px 0 15px -4px rgba(226, 226, 226, 0.8),
    -12px 0 8px -4px rgba(226, 226, 226, 0.8);
  border-radius: 10px;
}
.mobile-navbar span {
  font-size: 9px;
  margin-top: 2px;
}

.sticky.fixed {
  z-index: 100000000 !important;
}

.cart-qty-cls2 {
  background: #18768f;
  width: 20px;
  height: 20px;
  color: #fff;
  border-radius: 20px;
  text-align: center;
  font-size: 11px !important;
  line-height: 14px;
  font-weight: 600;
  padding: 3px;
  position: absolute;
  margin-top: -32px !important;
  margin-left: 15px;
}

.mobile-account-3 {
  position: relative !important;
  bottom: 0px !important;
  right: 0px !important;
}

@media (max-width: 577px) {
  header.header-6 .main-menu .brand-logo {
    padding: 0 !important;
    top: 0 !important;
    left: 0;
    -webkit-transform: translateX(0);
    transform: translateX(10%);
  }
  .mobile-wishlist i {
    display: none !important;
  }
  .mobile-cart i {
    display: none !important;
  }
  .mobile-account2 i {
    display: none !important;
  }
  .cart-qty-cls {
    display: none !important;
  }
}
@media (max-width: 1200px) {
  .main-menu .menu-left .navbar i {
    display: none;
  }
}

@media (max-width: 480px) {
  .brand-logo img {
    height: 35px;
  }
}

@media (min-width: 577px) {
  .main-menu .menu-left .navbar {
    padding: 40px 0px;
  }
  .onhover-div.mobile-setting {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .onhover-div.mobile-cart {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .mobile-navbar {
    display: none !important;
  }
}

@media (max-width: 700px) {
  .header-container-left {
    padding-left: 0px !important;
  }
}

@media (min-width: 1000px) {
  .searchbar-icon-box {
    background-color: #f54c3c;
    display: flex;
    border: 1px solid rgb(245, 76, 60);
    cursor: pointer;
  }

  .header-container-left .main-menu {
    justify-content: space-between !important;
  }
}

@media (max-width: 1000px) {
  .searchbar-icon-box {
    display: none;
  }
  .top-searchbar-parent {
    margin-top: 10px !important;
  }

  .mobile-view-container {
    margin-top: 10px !important;
  }
}
.brand-logo {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.track-order-button-2 {
  padding: 10px;
  padding-bottom: 13px;
  background-image: linear-gradient(90deg, #ff7e3e, #ff4000);
  border-radius: 5px;
  cursor: pointer;
}

.top-searchbar-container-2 {
  display: flex;
}

.top-searchbar-container-2 .top-form-2 {
  width: 700px !important;
}
.top-searchbar-parent-2 {
  padding-top: 0px !important;
}
@media (max-width: 480px) {
  .track-order-button-2 {
    padding: 7px;
    padding-bottom: 10px !important;
    background-image: linear-gradient(90deg, #ff7e3e, #ff4000);
    border-radius: 5px;
    cursor: pointer;
    font-size: 10px;
    margin-top: 0px;
    margin-right: 0px !important;
  }
  .onhover-div {
    padding-bottom: 0px !important;
  }

  .top-searchbar-container-2 {
    display: none;
  }
}

@media (min-width: 1000px) {
  .setting-icon-header {
    display: none;
  }
}

.category-parent-option {
  padding: 20px 0px;
  border-bottom: 1px solid gainsboro;
  font-size: 12px;
  font-weight: bold;
}
