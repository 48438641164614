.each-option {
  font-size: 15px;
  cursor: pointer;
}

.mobile-view {
  display: none;
}

.view-for-orders {
  display: block;
}
.each-option:hover {
  color: #f54c3c;
}
.only-option-container {
  padding: 5px;
  padding-left: 30px;
  margin-top: 10px;
}
/* .option-container {
} */

.left-list-column {
  padding: 10px 40px;
}
.left-list-container {
  background-color: white;
  padding: 25px;
  border-radius: 5px;
}
.vertical-line {
  width: 100%;
  height: 1px;
  background-color: gainsboro;
  margin-bottom: 15px;
}

.right-detail-column {
  padding: 10px 40px;
}

.top-row {
  padding: 12px;
  padding-bottom: 0px;
  background-color: white;
  border-radius: 5px;
}

.select-option {
  font-size: 16px;
  text-align: center;
  color: gray;
  cursor: pointer;
}

.main-order-section {
  background-color: white;
  border-radius: 5px;
}

.title-header {
  padding: 8px;
  background-color: white;
}

.title-header div {
  font-size: 12px;
  color: gray;
}

.order-header-1 {
  padding: 6px;
  background-color: rgb(255, 255, 255);
  width: 100%;
  justify-content: space-between;
  margin-left: 0px;
  display: flex;
  flex-direction: row;
}

.each-order-container-1 {
  background-color: white;
  justify-content: flex-start;
  padding: 10px;
  margin-top: 5px;
  padding-bottom: 0px;
}

.products-box {
  background-color: white;
  padding: 10px;
  padding-top: 0px;
}
.info-box {
  color: darkorange;
  border: 1px solid darkorange;
  padding: 0px 3px;
  font-size: 10px;
}
.search-bar-row-1 {
  padding: 20px;
  padding-right: 0px;
  justify-content: flex-end;
}
@media (max-width: 600px) {
  .mobile-view {
    display: block;
  }
  .view-for-orders {
    display: none;
  }

  .select-option {
    font-size: 10px !important;
  }

  .subscribe-form {
    display: flex;
    flex-direction: row;
  }

  .subscribe-form button {
    margin-left: -20px !important;
    padding: 6px 10px !important;
  }
  .subscribe-form input {
    padding: 8px !important;
    font-size: 12px;
  }

  .search-bar-row-1 {
    justify-content: center;
  }

  .each-order-container-1 {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .main-navigator-option {
    display: flex;
    flex-direction: row;
    background-color: #f8f8f8;
    justify-content: space-around;
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: nowrap !important;
  }

  .buy-ship-for-me-mobile {
    min-width: 180px;
    margin-left: 100px;
  }
}
@media (max-width: 350px) {
  .buy-ship-for-me-mobile {
    margin-left: 140px;
  }
}
