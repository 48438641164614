.in-stock {
  color: darkgreen !important;
}

.pre-order {
  color: orange !important;
}

.taobao {
  color: darkorange !important;
}

@media (max-width: 700px) {
  .wishlist-item-name {
    display: none;
  }
}
@media (min-width: 700px) {
  .mobile-cart-content {
    display: none;
  }
}
