.each-option {
  font-size: 12px;
  cursor: pointer;
}
.each-option:hover {
  color: #f54c3c;
}
.only-option-container {
  padding: 5px;
  padding-left: 30px;
  margin-top: 10px;
}
/* .option-container {
    } */
.left-list-column {
  padding: 10px 40px;
}
.left-list-container {
  background-color: white;
  padding: 25px;
  border-radius: 5px;
}
.vertical-line {
  width: 100%;
  height: 1px;
  background-color: gainsboro;
  margin-bottom: 15px;
}

.right-detail-column {
  padding: 10px 40px;
}

.top-row {
  padding: 12px;
  padding-bottom: 0px;
  background-color: white;
  border-radius: 5px;
}

.select-option {
  font-size: 16px;
  text-align: center;
  color: gray;
}

.main-order-section {
  background-color: white;
  border-radius: 5px;
}

.title-header {
  padding: 8px;
  margin-top: 7px;
  background-color: rgb(248, 248, 248);
}

.title-header div {
  font-size: 12px;
  color: gray;
}

.order-header {
  padding: 6px;
  background-color: #eaf5ff;
  width: 100%;
  justify-content: space-between;
  margin-left: 0px;
  padding-bottom: 15px;
  border-bottom: 0px;
}
.order-header-3 {
  padding: 10px;
  background-color: #eaf5ff;
  width: 100%;
  justify-content: space-between;
  margin-left: 0px;
  border-bottom: 0px;
}

.order-header-2 {
  padding: 8px;
  background-color: #fcfcff;
  width: 100%;
  justify-content: space-between;
  margin-left: 0px;
}

.each-order-container {
  background-color: white;
  justify-content: flex-start;
  margin-top: 10px;
  padding: 0px;
  padding-bottom: 0px;
}

.products-box-2 {
  background-color: #fcfcff;

  padding-top: 0px;
  border: 1px solid gainsboro;
}

.settlement-container {
  background-color: white;
  justify-content: space-between;
  z-index: 20000;
  width: 100%;
  margin: auto;
  margin-bottom: -10px;
  box-shadow: 0px 0px 10px #bdbdbd;
}
.sticky-settlement-container {
  position: sticky;
  bottom: 0;
}

@media (min-width: 1450px) {
  .main-container-cart {
    max-width: 1400px;
    margin: auto;
  }
  .parent-main-container-cart {
    background-color: rgb(248, 248, 248);
  }
}
@media (max-width: 600px) {
  .reduce-padding-bottom {
    padding-bottom: 0px !important;
  }
}

.size-input-checkbox {
  margin-top: 0px;
}

.user-info {
  background-color: white;
  padding: 20px;
  justify-content: flex-start;
  margin-top: 20px;
}
