.size-category {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.product-buttons {
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 10px 20px;
  box-shadow: 0px 0px 8px gainsboro;
  z-index: 900;
}

.error-message {
  position: sticky;
  bottom: 60px;
  z-index: 1000000;
}

.wishlist-btn {
  background-color: white;
  border: 1px solid #18768f;
  border-radius: 5px;
  color: #18768f !important;
  margin-left: 10px;
}

.order-now-button {
  background-color: #18768f;
  border: 1px solid #18768f;
  border-radius: 5px;
  padding: 9px 15px;
  color: white !important;
}

.size-price {
  font-size: 12px;
}

.form-control.input-number {
  width: 80px;
}

.local-courier-charge-div {
  padding-right: 10px;
}

.input-group {
  flex-wrap: nowrap !important;
}

.order-details-container-box {
  margin-right: 0px !important;
  padding-bottom: 20px !important;
}

.shipping-info-mobile-view {
  display: none !important;
}
.shipping-info-pc-view {
  display: block !important;
}
.shipping-info-pc-view-2 {
  display: flex !important;
}
@media (max-width: 991px) {
  .product-right .size-text {
    text-align: center;
  }
}
.price-box .price {
  font-size: 18px !important;
}
@media (max-width: 600px) {
  .china-local {
    font-size: 10px !important;
  }
  .shipping-info-mobile-view {
    display: block !important;
    z-index: 2 !important;
  }
  .shipping-info-pc-view {
    display: none !important;
  }
  .shipping-info-pc-view-2 {
    display: none !important;
  }
  .cn-key {
    font-size: 12px !important;
  }
  .cn-key span {
    font-size: 12px !important;
  }
  .color-container {
    justify-content: center;
  }
  .shipping-rate-hidden {
    left: -200px !important;
    top: 22px !important;
  }
  .product-right h6 {
    font-size: 90% !important;
  }

  .product-right p {
    font-size: 9px;
  }

  .service-list {
    font-size: 80% !important;
  }

  .product-buttons {
    bottom: 60px;
    padding: 10px 5px;
    justify-content: center;
  }

  .price-box .price {
    font-size: 14px !important;
  }

  .error-message {
    bottom: 120px;
    padding: 10px 5px;
  }

  .wishlist-btn,
  .order-now-button {
    font-size: 10px !important;
    padding: 9px !important;
  }

  .size-price {
    font-size: 10px;
  }

  .form-control.input-number {
    width: 40px !important;
    padding: 0px;
  }

  .quantity-left-minus,
  .quantity-right-plus {
    padding: 0px 4px;
  }

  .available-for-sale {
    font-size: 8px !important;
  }

  .local-courier-charge-div {
    padding-right: 0px;
  }
}
.quantity-left-minus {
  padding: 0px 6px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.quantity-right-plus {
  padding: px 6px;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.qty-box .input-group span button {
  background: #18768f !important;
  border: 1px solid #18768f;
}

.qty-box .input-group button i {
  font-weight: bold;
  color: white !important;
}

/* .flex-color-variant{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
} */

.border-red {
  border: 1px solid #18768f !important;
  border-radius: 0% !important;
  padding: 0.1rem;
  width: fit-content;
  height: fit-content;
}
.border-gray {
  border: 1px solid rgb(219, 217, 217) !important;
  border-radius: 0% !important;
  padding: 0.1rem;
  width: fit-content;
  height: fit-content;
}

.shipping-method {
  display: flex;
  flex-direction: column;
}

.color-variant-image {
  width: 100%;
  padding: 2px;
}
.service-list {
  margin-bottom: 1rem !important;
  text-align: start;
}
.service-list span {
  font-size: 130%;
  color: darkgreen;
}

.price-range-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.price-range-container .price-box {
  border: 0.1px solid lightgray;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 5rem;
  width: 13rem;
  justify-content: center;
}

.price-range-container .price-box h3 {
  margin-top: 5px;
}

.price-range-container .price-box.selected {
  background: #18768f;
}

.price-range-container .price-box.selected h3 {
  color: white;
}
.price-range-container .price-box.selected p {
  color: white;
}

.product-right h3 {
  font-size: 26px;
  color: #f48110;
  margin-bottom: 15px;
  font-weight: bold;
}

.product-detail-title {
  color: black;
  font-size: 100%;
  font-family: sans-serif;
  margin-bottom: 0px;
  font-weight: bold;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.rating i:last-child {
  color: #ffa200;
}

.hide {
  display: none;
}

.myDIV:hover + .hide {
  display: block;
}

.myDIV2:hover + .hide {
  display: block;
}

.list-group-item {
  padding: 10px;
  border: 1px solid #ddd;
  background: #fff;
  margin-bottom: 0px !important;
}

.list-group-item:hover {
  background: #f8f9fa;
}
