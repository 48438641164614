.cart-total-black {
  color: black !important;
}

.product-variant {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
