@media (min-width: 990px) {
  .order-tracking-modal-result {
    max-width: 40%;
  }
}
.order-tracking-bar span {
  font-size: 250%;
  font-weight: bold;
  margin-bottom: 0px;
  padding: 0px;
}

.order-tracking-bar span div i {
  border: 3px solid gainsboro;
  border-radius: 10rem;
  padding: 5px;
  color: gainsboro;
}

.order-tracking-bar span p {
  font-size: 30%;
  display: flex;
  margin-top: 14px;
}

.icon-and-line-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.progress-bar-line {
  height: 35px;
  width: 3px;
  background-color: gainsboro;
  align-self: center;
}

.input-container-div {
  width: 50%;
  margin: auto;
}

.tracking-error-div {
  width: 50%;
  margin: auto;
  color: red;
}

.track-order-button-div {
  /* width: 15%; */
  margin: auto;
  background-color: #ec6729;
  padding: 15px;
  border-radius: 5px;
  min-height: 50px;
  max-height: 50px;
  min-width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.track-your-order-txt {
  color: white;
  font-weight: bold;
  justify-content: center;
  font-size: 30px;
  padding-top: 20px;
  margin-bottom: 20px;
}

.tracking-no-header {
  color: white;
  font-weight: bold;
  justify-content: center;
  padding: 10px;
  font-size: 30px;
}

.result-container {
  background-color: rgba(1, 70, 109, 1);
  border-radius: 5px;
  padding: 50px;
  padding-top: 10px;
  margin-top: 20px;
  padding-bottom: 40px;
}

.section-containing-container {
  display: flex;
  flex-direction: row;
}
.flex-options {
  flex: 1;
  padding: 10px;
}
@media (max-width: 480px) {
  .input-container-div {
    width: 100%;
  }
  .tracking-error-div {
    width: 100%;
  }

  /* .track-order-button-div {
    /* width: 100%; */

  .track-order-container {
    padding-top: 20px;
  }
  .track-your-order-txt {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .tracking-no-header {
    font-size: 14px;
  }

  .result-container {
    padding: 0px;
  }

  .section-containing-container {
    display: flex;
    flex-direction: column;
  }
  .flex-options {
    padding: 0px;
  }
  .cross-icon {
    margin-right: 7px;
  }
}
