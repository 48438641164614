.image-container {
  max-width: 100%;
  min-width: 100%;
  height: auto;
  margin: auto;
  padding-bottom: 5px;
  display: flex;
  border-radius: 10px;
}

.magnify-image {
  border-radius: 10px;
}

@media (max-width: 600px) {
  .image-container {
    max-height: 400px;
    min-height: 400px;
    max-width: 100%;
    min-width: 100%;
    overflow: hidden;
  }

  .image-container div img {
    height: 400px !important;
    width: 100% !important;
  }
  .magnify-image {
    height: 400px !important;
    width: 100% !important;
  }
}
@media (max-width: 400px) {
  .image-container {
    max-height: 350px;
    min-height: 350px;
  }
  .image-container div img {
    height: 350px !important;
    width: 100% !important;
  }
  .magnify-image {
    height: 350px !important;
    width: 100% !important;
  }
}
@media (max-width: 320px) {
  .image-container {
    max-height: 300px;
    min-height: 300px;
  }
  .image-container div img {
    height: 300px !important;
    width: 100% !important;
  }
  .magnify-image {
    height: 300px !important;
    width: 100% !important;
  }
}
@media (min-width: 1000px) {
  .image-container {
    max-height: 420px;
    min-height: 420px;
  }

  .magnify-image {
    z-index: 100;
  }
}
@media (min-width: 1500px) {
  .image-container {
    max-height: 520px;
    min-height: 520px;
  }
}
