.blinking-text{
    animation: blink 1s linear infinite;
}
@media (min-width : 1200px){
    .blinking-text{
        margin-left: 1rem;
    }
}
@keyframes blink{
    50%{
        opacity: .3;
    }
}
.new-arrival {
    color: rgb(255, 166, 0) !important;
}

.navigation-page{
    font-size: 12px !important;
}

@media (min-width: 1199px){
    header .main-navbar .nav-menu > li:hover .mega-menu-container {
      top: 3rem !important;
    }

    header .main-navbar .nav-menu > li:hover .nav-submenu {
        top: 5rem !important;
    }
}
@media (max-width: 1199px){
    header .main-navbar .nav-menu > li .mega-menu-container {
     position: relative;
    }
}
@media (max-width: 1199px){
    header.header-6 .main-nav-center .toggle-nav{
       top:-110px;
    }
}
@media (max-width: 577px){
    header.header-6 .main-nav-center .toggle-nav{
       top:-40px;
       right: 25px;
    }
}
@media (max-width: 450px){
    header.header-6 .main-nav-center .toggle-nav{
       top:-40px;
       right: 16px;
    }
}


