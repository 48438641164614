.taobao-support {
  text-transform: none !important;
}

.header-text-box {
  padding: 0 !important;
}

.image-input-container {
  display: flex;
  flex-direction: row;
}

.search-by-image {
  cursor: pointer;
  font-size: 18px !important;
  color: #ff4c3b;
  padding-left: 15px;
  padding-right: 15px;
  background: darkslategray;
  padding-top: 12px;
}
.upload-image {
  cursor: pointer;
  overflow: hidden !important;
  max-width: 50px;
  opacity: 0;
  position: absolute;
  min-height: 45px;
}

@media (min-width: 1000px) {
  .home-slider .home.home45,
  .center-slider .home.home45 {
    width: 900px;
    height: 300px;
    border-radius: 5px;
  }
  .home-slider .home.home46,
  .center-slider .home.home46 {
    width: 900px;
    height: 300px;
    border-radius: 5px;
  }
}
@media (min-width: 1000px) {
  .home-page-main-slider {
    width: 900px;
    height: 300px;
    border-radius: 10px;
  }
}
@media (min-width: 1000px) {
  .slider-inquiry-bar {
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* background-color: rgb(250, 250, 250); */
    padding: 30px;
    background-color: #f8f8f8;
  }
}

.next-button:hover {
  background-color: orange;
}

.select-outline-style:active,
.select-outline-style:hover {
  outline-color: orange;
  border-color: orange;
}

.select-outline-stye:focus {
  outline-color: orange;
  border-color: orange;
}

.enquiry-box-container {
  height: 100%;
  width: 260px;
  border: 1px solid #ff8c00;
  border-radius: 10px;
  padding: 25px;
  background-color: rgb(255, 252, 249);
  padding-top: 60px;
}
.enquiry-box {
  padding: 30px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.button-container-div {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media (max-width: 480px) {
  .enquiry-box-container {
    width: 100%;
    padding-top: 25px;
  }
  .enquiry-box {
    padding: 15px;
    padding-bottom: 20px;
  }

  .button-container-div {
    padding-top: 10px;
  }
}
