.selected-option {
  color: black !important;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 2px solid #f54c3c;
}

@media (max-width: 600px) {
  .form-center {
    justify-content: center;
  }
}
