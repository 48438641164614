.sync-loader-container {
  position: absolute;
  bottom: -50px;
  left: 50%;
}

@media (max-width: 600px) {
  .sync-loader-container {
    position: absolute;
    bottom: 0px;
    left: 40%;
  }

  .product-container-col {
    width: 50% !important;
    max-width: 50% !important;
    padding: 5px !important;
  }
}
