.product-box {
  border: 1px solid gainsboro !important;
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  border-radius: 5px;
}
.product-box:hover {
  border: 1px solid #18768f !important;
}

.img-wrapper {
  border-bottom: 2px solid #18768f;
}

@media (min-width: 800px) {
  .product-image-collection {
    min-width: 100%;
    max-height: 193px;
    min-height: 193px;
  }

  .product-box {
    min-width: 100%;
    max-height: 315px;
    min-height: 315px;
    overflow: hidden;
  }
}
@media (max-width: 800px) {
  .product-image-collection {
    min-width: 100%;
    max-height: 200px;
    min-height: 200px;
  }

  .product-box {
    min-width: 100%;
    max-height: 400px;
    min-height: 400px;
    overflow: hidden;
  }
}
@media (max-width: 600px) {
  .product-title-name {
    font-size: 10px !important;
  }
  .product-image-collection {
    min-width: 100%;
    max-height: 180px;
    min-height: 180px;
    background-size: 90px 90px !important;
  }

  .product-box {
    min-width: 100%;
    max-height: 300px;
    min-height: 300px;
    overflow: hidden;
  }
}
@media (max-width: 400px) {
  .product-image-collection {
    min-width: 100%;
    max-height: 160px;
    min-height: 160px;
  }
  .product-box {
    min-width: 100%;
    max-height: 275px;
    min-height: 275px;
    overflow: hidden;
  }
}
@media (max-width: 350px) {
  .product-image-collection {
    min-width: 100%;
    max-height: 135px;
    min-height: 135px;
  }
  .product-box {
    min-width: 100%;
    max-height: 250px;
    min-height: 250px;
    overflow: hidden;
  }
}
