.slick-prev:before,
.slick-next:before {
  font-size: 15px !important;
  line-height: 3.8 !important;
  color: black !important;
}

.webview-hidden {
  display: none !important;
}

@media (max-width: 600px) {
  .webview-hidden {
    display: block !important;
  }
  .product-container-section {
    margin-top: 0px !important;
    padding-top: 5px !important;
  }
}
